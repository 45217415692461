.leaflet-container {
    width: 40vw;
    max-width: 540px;
    max-height: 540px;
    height: 40vw;
    border-radius: 10px;

    @media screen and (max-width: 700px)
        {
            width: 250px;
            height: 250px;
        };
}