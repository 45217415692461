.leaflet-container {
    width: 500px;
    height: 500px;
    border-radius: 10px;

    @media screen and (max-width: 700px)
        {
            width: 250px;
            height: 250px;
        };
}